<template>
	<div id="adoptList">
		<page-adopt-manager :is-shop="1"></page-adopt-manager>
	</div>
</template>

<script>
	import pageAdoptManager from '@/components/layout/adopt/page-adopt-manager.vue'
	export default{
		components:{
			pageAdoptManager
		},
		inject:["reload"],		//刷新页面
	}
</script>

<style lang="scss">
</style>
